import * as React from "react";
import { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import axios from "axios";
import Seo from "../components/Seo";
import CandidateModal from "../components/CandidateModal";
import WardResultHero from "../components/WardResultHero";
import CandidateProfile from "../components/CandidateProfile";
import SignUpSection from "../components/SignUpSection";

export default function Ward({ data }) {
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [modalProxyOpen, setModalProxyOpen] = useState(false);
  const [modalCandidateOpen, setModalCandidateOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [results, setResults] = useState(null);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [showFinalResult, setShowFinalResult] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('N/A');
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { frontmatter } = data.ward;
  
  const wardName = frontmatter.ward_name;
  const wardImage = frontmatter.ward_image;
  const wardCandidates = frontmatter.ward_candidates;
  const wardIntroduction = frontmatter.ward_introduction;
  const showCandidates = frontmatter.show_candidates_profiles;
  const meetYourCandidates = frontmatter.meet_your_candidates;
  const showElectionsResults = frontmatter.show_election_results;
  const candidatesSubtitle = frontmatter.candidates_section_subtitle;


  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves elections results
  const getWardData = async (wardName) => {
    try {
      
      // Tectonica account 
      // Main function
      // const response = await axios.get(`https://getwarddata-aikt6r77tq-uc.a.run.app/?ward=${encodeURIComponent(wardName)}`);
      // Mirror function 
      // const response = await axios.get(`https://getwarddata-ooc5bflr5a-uc.a.run.app/?ward=${encodeURIComponent(wardName)}`);

      // Client account
      // Main function
      const response = await axios.get(`https://getwarddata-x52n4zspbq-uc.a.run.app/?ward=${encodeURIComponent(wardName)}`);
      // Mirror function 
      // const response = await axios.get(`https://getwarddata-ad75scrbwa-uc.a.run.app/?ward=${encodeURIComponent(wardName)}`);

      setShowFinalResult(response.data.showResults);
      console.log('Result', response.data);
      const responseData = response.data.candidates;
      const sortedData = responseData.sort((a, b) => b.votes - a.votes);
      setLastUpdated(response.data.lastUpdated ? response.data.lastUpdated : 'N/A');
      setResults(sortedData);
      setDataRetrieved(true);
      return response.data;
    } catch (error) {
      console.error('Error fetching ward data:', error);
      throw error;
    }
  };

// RETURNED DATA STRUCTURE FROM API
// {
//   "name": "Aldgate",
//   "showResults": true,
//   "candidates": [
//       {
//           "candidate": "Andrien Meyers",
//           "party": "Independent",
//           "votes": 203,
//           "elected": true
//       },
//   ],
//   "statistics": {
//       "totalVotes": 1132,
//       "totalCandidates": 7,
//       "electedCandidates": 5
//   }
// }
 

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(() => getWardData(wardName), refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval, wardName]);

  useEffect( () => {
    if(!dataLoaded) {
      getWardData(wardName)
        // .then(data => console.log(data))
        .catch(error => console.error(error));  

    }
  }, [dataLoaded, wardName]);

  return(
    <>
      <Seo title="Ward" />
      { modalCandidateOpen && <CandidateModal candidate={selectedCandidate} ward={wardName} meetInfo={meetYourCandidates} setModalOpen={setModalCandidateOpen}/>}

      <div className="page-content page-content--election-result">
        <div className="container">
          <div className="row">

            <div className="col-md-6 col-left">
              {
                <WardResultHero 
                  wardName={wardName} 
                  wardImage={wardImage}
                />
              }

              <div className="page__introduction">
                <h2>Introduction</h2>
                <p>{wardIntroduction}</p>
              </div>

              <div className="page__candidates">
                <h2>Your Candidates for {wardName}</h2>
                {
                  candidatesSubtitle
                   &&
                  <>
                    <h3>{candidatesSubtitle}</h3>
                    <span className="headline-underline"/>
                  </>
                }
                <div className="candidates">
                  {
                    !showCandidates ?
                     <p>Details of your candidates will be published here later this week. You can see who has been nominated by viewing the <a href="https://www.cityoflondon.gov.uk/about-us/voting-elections/statements-of-persons-nominated">Statements of Persons Nominated</a>.</p>
                    
                    :
                      wardCandidates.map(candidate => (
                        <CandidateProfile
                          candidate={candidate}
                          setModalCandidateOpen={setModalCandidateOpen}
                          setSelectedCandidate={setSelectedCandidate}
                        />
                      ))
                  }
                </div>

              </div>

              
            </div>

            <div className="col-md-6 col-right">
              <div className="margin-bg"></div>

              { !dataRetrieved && <h2 className="result__headline">Loading...</h2> }

              {
                (showFinalResult && dataRetrieved) 
                  &&
                  <>
                    <h2 className="result__headline">Results Declared <div className="result__updated">Last updated: { new Date(lastUpdated).toLocaleString('en-US', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) }</div></h2>
                  </>
              }
              {
                (!showFinalResult && dataRetrieved) 
                  &&
                <>
                  <h2 className="result__headline">Counting Underway</h2>
                  <p className="result__text">Votes for this ward are still being counting.<br/>Please check later.</p>
                  <Link className="result__link" to="/results">Go back to map page</Link>
                </>
              }

              <ul className="result__list">

                {
                  results?.map(result => (
                    <li className={`result__item ${result.elected === 'TRUE' ? 'result__item--elected' : null}`}>
                      {
                        (showFinalResult && dataRetrieved) 
                          &&
                        <div className={`result__votes ${String(result.votes).toLowerCase()}`}>{result.votes} {String(result.votes).toLowerCase() !== 'uncontested' && <span>Votes</span>}</div>
                      }
                      <div className="result__candidate">
                        <span className="candidate__name">{result.candidate}</span>
                        <div className="candidate__labels">
                          <span className={`candidate__party ${result.party?.replace(/\s+/g, '-').replace("'", '').toLowerCase()}`}>{result.party}</span>
                          {
                            result.elected 
                              &&
                            <span className="candidate__party elected">Elected</span>
                          }
                        </div>
                      </div>
                    </li>
                  ))
                }

              </ul>
              
            </div>

          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}


export const query = graphql`
  query WardResult($id: String!) {
    ward: markdownRemark(id: {eq: $id}) {
      frontmatter {
        ward_name
        ward_introduction
        candidates_section_subtitle
        show_candidates_profiles
        there_are_elections
        ward_candidates {
          candidate_statement
          candidate_name
          candidate_party
          candidate_profile_picture {
            childImageSharp {
              gatsbyImageData
            }
          }
          telephone_number
          candidate_email
          website
          twitter
          linkedin
        }
        ward_voting_places {
          location_address
          location_url
          location_name
          type
        }
        ward_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        meet_your_candidates
        candidates_number
        show_election_results
      }
    }
  }
`;